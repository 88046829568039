<template>
  <div class="default-layout">
    <overlay v-if="overlayActive" />
    <loader />
    <div id="viewport" class="w-100 relative">
      <main-header />
      <async-sidebar
        :async-component="SearchPanel"
        :is-open="isSearchPanelOpen"
        @close="$store.commit('ui/setSearchpanel')"
      />
      <async-sidebar
        :async-component="Microcart"
        :is-open="isMicrocartOpen"
        @close="$store.commit('ui/setMicrocart')"
      />
      <no-ssr>
        <cookies />
      </no-ssr>
      <configurator v-if="isConfiguratorOpen" />
      <chat-bot-modal @close="$store.dispatch('ui/setChatBot',false)" v-if="isChatBotOpen" />
      <no-ssr>
        <cookies />
      </no-ssr>
      <slot />
      <main-footer />
      <notification />
      <sign-up />
      <offline-badge />
      <order-confirmation :orders-data="ordersData" v-if="loadOrderConfirmation" />
    </div>
    <vue-progress-bar />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AsyncSidebar from 'theme/components/theme/blocks/AsyncSidebar/AsyncSidebar.vue'
import MainHeader from 'theme/components/core/blocks/Header/Header.vue'
import MainFooter from 'theme/components/core/blocks/Footer/Footer.vue'
import Overlay from 'theme/components/core/Overlay.vue'
import Loader from 'theme/components/core/Loader.vue'
import Notification from 'theme/components/core/Notification.vue'
import SignUp from 'theme/components/core/blocks/Auth/SignUp.vue'
import OfflineBadge from 'theme/components/core/OfflineBadge.vue'
import Cookies from '../components/core/blocks/Cookies/Cookies';
import { isServer } from '@vue-storefront/core/helpers'
import Head from 'theme/head'
import config from 'config'
import NoSSR from 'vue-no-ssr'
import Configurator from '../components/theme/anyrent/configurator/Configurator';
import PartnerModalSbcr from 'theme/components/theme/sbcr/PartnerModalSbcr.vue';
import ChatBotModal from '../components/core/blocks/ChatBot/ChatBotModal';
import sbcrConfig from '../../../../core/partner-config/config.sbcr.ts';

const Microcart = () => import(/* webpackPreload: true */ /* webpackChunkName: "vsf-microcart" */ 'theme/components/core/blocks/Microcart/Microcart.vue')
const SearchPanel = () => import(/* webpackChunkName: "vsf-search-panel" */ 'theme/components/core/blocks/SearchPanel/SearchPanel.vue')
const OrderConfirmation = () => import(/* webpackChunkName: "vsf-order-confirmation" */ 'theme/components/core/blocks/Checkout/OrderConfirmation.vue')

export default {
  data () {
    return {
      loadOrderConfirmation: false,
      ordersData: [],
      Microcart,
      SearchPanel,
      isPartnerModalVisible: false
    }
  },
  computed: {
    ...mapState({
      overlayActive: state => state.ui.overlay,
      isSearchPanelOpen: state => state.ui.searchpanel,
      isMicrocartOpen: state => state.ui.microcart,
      isWishlistOpen: state => state.ui.wishlist,
      isConfiguratorOpen: state => state.ui.configurator,
      isChatBotOpen: state => state.ui.chatBot
    })
  },
  methods: {
    onOrderConfirmation (payload) {
      this.loadOrderConfirmation = true
      this.ordersData = payload
      this.$bus.$emit('modal-show', 'modal-order-confirmation')
    },
    fetchMenuData () {
      return this.$store.dispatch('category/list', { level: config.entities.category.categoriesDynamicPrefetch && config.entities.category.categoriesDynamicPrefetchLevel >= 0 ? config.entities.category.categoriesDynamicPrefetchLevel : null, includeFields: config.entities.optimize && isServer ? config.entities.category.includeFields : null, skipCache: isServer })
    },
  },
  serverPrefetch () {
    return this.fetchMenuData()
  },
  beforeMount () {
    // Progress bar on top of the page
    this.$router.beforeEach((to, from, next) => {
      this.$Progress.start()
      this.$Progress.increase(40)
      next()
    })
    this.$router.afterEach((to, from) => {
      this.$Progress.finish()
    })
    this.$bus.$on('offline-order-confirmation', this.onOrderConfirmation)
  },
  beforeDestroy () {
    this.$bus.$off('offline-order-confirmation', this.onOrderConfirmation)
  },
  metaInfo: Head,
  components: {
    ChatBotModal,
    Configurator,
    'no-ssr': NoSSR,
    Cookies,
    MainHeader,
    MainFooter,
    Overlay,
    Loader,
    Notification,
    SignUp,
    OfflineBadge,
    OrderConfirmation,
    AsyncSidebar,
    PartnerModalSbcr
  }
}
</script>

<style lang="scss" src="theme/css/main.scss"></style>
