<template>
  <div>
    <div class="fb-login-button"
         onlogin="logInWithFacebook()"
         data-size="medium"
         data-button-type="continue_with"
         data-layout="default"
         data-auto-logout-link="false"
         data-use-continue-as="true"
         data-width=""
         data-scope="email"
    />
  </div>
</template>
<script>
import Login from '@vue-storefront/core/compatibility/components/blocks/Auth/Login'
import config from 'config'

export default {
  name: 'FacebookLogin',
  mixins: [Login],
  data () {
    return {
      facebookAuthId: config.socialLogins.facebookOauthId || 'unknown'
    }
  },
  async mounted () {
    window.logInWithFacebook = this.logInWithFacebook // connecting global with local scope for FB button
    await this.loadFacebookSDK(document, 'script', 'facebook-jssdk');
    await this.reInitFacebook()
  },
  methods: {
    async logInWithFacebook (response) {
      window.FB.getLoginStatus((response) => {
        if (response.authResponse) {
          window.FB.api('/me', 'GET', {'fields': 'id,email,first_name,last_name'}, (userResponse) => {
            // merge the objects
            const payload = response.authResponse;
            payload.first_name = userResponse.first_name
            payload.last_name = userResponse.last_name
            payload.email = userResponse.email
            this.$emit('fbSuccess', payload)
          });
        } else {
          this.$emit('fbFail', response)
        }
      });
    },
    async reInitFacebook () {
      if (window.FB) {
        window.FB.XFBML.parse()
      }
    },
    async loadFacebookSDK (d, s, id) {
      var js;
      var fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = `https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v16.0&appId=${this.facebookAuthId}&autoLogAppEvents=1`;
      fjs.parentNode.insertBefore(js, fjs);
    }
  }
};
</script>
<style>
.button{
  color:white;
  min-width: 150px;
  background-color: #000000a1;
  height: 2.5rem;
  border-radius: 2rem;
  font-weight: 400;
  font-size: 0.8rem;
  border: 0px;
}
</style>
