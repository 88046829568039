<template>
  <div class="chat-bot-form flex flex-direction-row h-100">
    <div class="chat-bot-top">
      <div v-if="!emailSent && !error">
        <div class="px50 px10-xs">
          <div>
            <label for="message" class="title-h1">
              <span class="block label-text-style label-text--md  cl-accent">
                {{ $t('Do you need a help?') }}
              </span>
              <span class="block font-family-regular label-text--xs cl-accent mt35">
                {{ $t('Leave us message and we will contact you.') }}
              </span>
            </label>
          </div>
          <div class="mb30" />
          <base-input
            class="mb30 font-family-regular input"
            type="text"
            :placeholder="$t('Your question')"
            v-model.trim="message"
          />
          <base-input
            class="mb30 font-family-regular input wrapped-label "
            type="text"
            :placeholder="`${$t('Your e-mail or phone number')} *`"
            v-model.trim="contactInfo"
          />
        </div>
      </div>
      <div v-if="error || emailSent">
        <div>
          <div class="info-container_notification">
            <div v-if="emailSent && !error">
              <span
                class="block label-text-style label-text--md cl-accent"
              > {{ $t('Thank you for your message, we will contact you.')
              }} </span>
              <span
                class="block font-family-regular label-text--xs cl-accent mt30 font-size-24"
              > {{ $t('We usually reply within 24 hours.')
              }} </span>
            </div>
            <div v-if="error">
              <span class="block label-text-style label-text--md cl-accent mt30 font-size-24">
                {{ $t('An error occurred during sending the message, please try it later.') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="chat-bot-bottom h-100 bg-cl-white-smoke">
      <div v-if="!emailSent && !error">
        <div class="consent-container cl-gray mb30 px50 px10-xs mt25">
          <p>
            {{ $t('By clicking on send button you consent to the') }}
            <router-link
              @click.native="$emit('close')"
              class="underline bold font-tt-commons-medium cl-accent"
              :to="localizedRoute({ name: 'privacy-policy' })"
              exact
            >
              {{ $t('personal data processing agreement.') }}
            </router-link>
          </p>
        </div>
        <div class="button-container">
          <select-button
            class="search-button h42"
            :class="{'search-button--disabled':!isComplete,'search-button--enabled':isComplete}"
            :disabled="!isComplete"
            :selected="true"
            @click.native="submit"
          >
            {{ $t('Send') }}
          </select-button>
        </div>
      </div>
      <div v-else>
        <div class="button-container mt40">
          <select-button
            class="search-button h42"
            :selected="true"
            @click.native="$emit('close')"
          >
            {{ $t('Close') }}
          </select-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EmailForm } from '@vue-storefront/core/modules/mailer/components/EmailForm';
import SelectButton from '../../../theme/anyrent/configurator/SelectButton';
import BaseInput from '../Form/BaseInput';
import config from 'config';

export default {
  name: 'ChatBotForm',
  components: { SelectButton, BaseInput },
  mixins: [EmailForm],
  data () {
    return {
      message: '',
      contactInfo: '',
      emptyMessage: true,
      focusedMessage: false,
      emptyContact: true,
      emailSent: false,
      isComplete: false,
      error: false
    };
  },
  methods: {
    async submit () {
      const targetMail = config?.mailer.contactAddress;
      const subject = `[Chatbot] ${this.$t('Message from')}: ${this.contactInfo}`;
      const emailText =
        `${this.$t('Message')}:
         ${this.message}

      ${this.$t('Customer contact')}:
       ${this.contactInfo}`;

      const mailContent = {
        sourceAddress: targetMail,
        targetAddress: targetMail,
        subject,
        emailText
      };

      this.sendEmail(mailContent, this.onSuccess, this.onFailure);
    },
    onSuccess (message) {
      this.emailSent = true;
    },
    onFailure (message) {
      this.error = true;
    },

    handleMessageFocusOut (event) {
      this.focusedMessage = false;
      this.emptyMessage = !event.target.value.length;
    },
    handleMessageFocusIn (event) {
      this.focusedMessage = true;
      this.emptyMessage = false;
    }
  },
  watch: {
    message (val) {
      this.emptyMessage = !!val.length;
      this.isComplete = !!this.message.length && !!this.contactInfo.length;
    },
    contactInfo (val) {
      this.isComplete = !!this.message.length && !!this.contactInfo.length;
    }
  }
};

</script>

<style lang='scss' scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';

$color-anyrent: color(partner);
$color-gray: color(gray);
$color-suva-gray: color(suva-gray);
$color-white-smoke: color(white-smoke);
$border-primary: color(primary, $colors-border);
$color-light-gray: color(light-gray);

.cl-suva {
  color: $color-suva-gray;
}

.cl-white-smoke {
  color: $color-suva-gray;
}

.cl-border-primary {
  color: $border-primary;
}

.chat-bot-form {
  width: 100%;
  height: 100%;
}

.label-text--md {
  font-size: 30px;
}

.label-text--sm {
  font-size: 22px;
}

.contact-container {
  display: flex;
  justify-content: center;
}

.contact-input {
  height: 50px;
  width: 500px;
  outline: none;
  border: none;
  color: black;
  border-bottom: 1px solid $color-gray;
}

.contact-input::placeholder {
  color: $color-gray;
}

.contact-input:hover {
  border-bottom: 1px solid $color-anyrent;
}

.contact-input:focus {
  border-bottom: 1px solid $color-anyrent;
}

.center-text-both {
  text-align: center;
  text-align-last: center;
  padding-top: 60px !important;
}

.message-textarea {
  outline: none;
  border: 1px solid $color-gray;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 10px;
  resize: none;
}

.message-container {
  height: 140px;
}

.message-textarea:focus {
  border: 1px solid $color-anyrent;
}

.message-textarea:hover {
  border: 1px solid $color-anyrent;
}

.center-text {
  text-align: center;
}

.bottom-stripe {
  width: 100%;
  height: 150px;
  bottom: 0;
}

.font-family-regular {
  font-family: 'TTCommons-Regular';
}

.label-text--xs {
  font-size: 18px;
}

.title-h1 {
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 30px;
}

.consent-container {
  width: 100%;
  font-family: TTCommons-Regular, sans-serif;
}

.consent-container p {
  padding-right: 10px;
  text-align: center;
}

a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: $border-primary;
}

.button-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.search-button {
  background-color: $color-light-gray;
  height: 42px;
}

.search-button--disabled {
  opacity: 0.9;
}

.search-button--enabled {
  background-color: $color-anyrent;
  opacity: 1;
}

.label-text-style {
  font-family: TTCommons-DemiBold, sans-serif;
  line-height: 32px;
}

.font-tt-commons-medium {
  font-family: TTCommons-Medium, sans-serif;
}

.info-container {
  padding-top: 110px;
  font-family: TTCommons-Medium, sans-serif;
  line-height: 32px;
}

.info-container_notification {
  display: flex;
  justify-content: center;
  height: calc(100% - 42px);
}

.flex-direction-row {
  flex-direction: column;
}

.underline {
  &:after {
    opacity: 0;
  }

  &:hover {
    &:after {
      opacity: 1;
      background-color: $color-gray;
    }
  }
}

@media (max-width: 572px) {

  .chat-bot-form {
    margin-top: 40px;
  }
  .message-container {
    height: 200px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .contact-container {
    padding-right: 10px;
    padding-left: 10px;
  }

  .info-container {
    padding-top: 50px;
  }

  .label-text--md {
    font-size: 24px;
  }

  .label-text--sm {
    font-size: 18px;
  }
  .px10-xs {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 400px) {
  .wrapped-label::v-deep .relative label {
    top: -10px;
  }
}

</style>
