<template>
  <div>
    <div class="heading">
      <h2 class="h2 cl-accent">
        {{ optionsLayer && optionsLayer.category_name ? optionsLayer.category_name : '...' }}
      </h2>
    </div>
    <div class="options-wrapper mt10">
      <div class="options">
        <slider
          class="mb20"
          :key="`slider_${index}`"
          v-for="(option,index) in optionsLayer.options"
          v-if="option.option_type === 'range'"
          :min="option.option_value.minimum"
          :max="option.option_value.maximum"
          :label="option.label"
          :unit="option.option_value.unit_of_measurement"
          @change="(value)=>handleSliderChanged(option,value)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Slider from './Slider';
import { RANGE } from '../../../../../../constants/EquipmentGuideTypes';

export default {
  name: 'SliderGroup',
  components: { Slider },
  props: {
    optionsLayer: {
      type: Object,
      required: true
    },
    sliderChangeHandler: {
      type: Function,
      default: () => {
      }
    },
    data () {
      return {
        RANGE
      };
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rowIndex: {
      type: Number,
      default: 0
    }
  },
  methods: {
    handleSliderChanged (option, value) {
      this.sliderChangeHandler(option, value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
@import '~theme/css/components/_checkboxes';
@import '~theme/css/layout/_margin';

$color-silver: color(silver);
$color-active: color(anyrent);
$color-white: color(white);
$color-anyrent: color(anyrent);
$color-matterhorn: color(matterhorn);

.heading {
  display: flex;
  flex: 1;
  justify-content: center;
}

.h2 {
  font-size: 18px;
  font-weight: lighter;
  font-family: TTCommons-Regular, sans-serif;
}

.options {
  max-width: 600px;
  width: 100%;
}

.options-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
}
.mb20{
  margin-bottom: 20px;
}

</style>
