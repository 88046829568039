




import Vue from 'vue';

export default Vue.extend({
  name: 'PartnerLogo',
  props: {
      // SVG Path must be relative to the Public directory!!
    svgPath: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      svgContent: ''
    };
  },
  mounted() {
    this.loadSvg(this.svgPath);
  },
  methods: {
    async loadSvg(path: string) {
      try {
        const response = await fetch(path);
        this.svgContent = await response.text();
      } catch (error) {
        console.error('Error loading SVG:', error);
      }
    }
  }
});
