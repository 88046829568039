<template>
  <div>
    <header class="modal-header py25 px65 h1 serif weight-700 bg-cl-secondary">
      {{ $t('Log in') }}
      <i
        slot="close"
        class="modal-close material-icons cl-bg-tertiary"
        @click="close"
      >
        close
      </i>
    </header>
    <div v-if="hasRedirect" class="pt10 pb10 px65 redirect-error">
      <p class="h5 mb0 mt0">
        {{ $t('You need to be logged in to see this page') }}
      </p>
    </div>
    <div class="modal-content bg-cl-primary pt30 pb60 px65 cl-secondary">
      <form @submit.prevent="login" novalidate>
        <base-input
          class="mb10"
          type="email"
          name="email"
          focus
          v-model="email"
          @blur="$v.email.$touch()"
          :placeholder="$t('E-mail address *')"
          :validations="[
            {
              condition: !$v.email.required && $v.email.$error,
              text: $t('Field is required.')
            },
            {
              condition: !$v.email.email && $v.email.$error,
              text: $t('Please provide valid e-mail address.')
            }
          ]"
        />
        <base-input
          class="mb10"
          type="password"
          name="password"
          v-model="password"
          @blur="$v.password.$touch()"
          :placeholder="$t('Password *')"
          :validations="[{
            condition: !$v.password.required && $v.password.$error,
            text: $t('Field is required.')
          }]"
        />
        <div class="row">
          <base-checkbox
            class="col-xs-7 col-sm-6 mb20"
            id="remember"
            v-model="remember"
          >
            {{ $t('Remember me') }}
          </base-checkbox>
          <div class="col-xs-5 col-sm-6 mb35 flex end-xs middle-xs">
            <a href="#" @click.prevent="remindPassword">
              {{ $t('Forgot the password?') }}
            </a>
          </div>
        </div>
        <div class="row">
          <!-- fix to six for fb/ggl -->
          <div class="col-sm-12">
            <div class="center-xs mt20">
              <facebook-login @fbSuccess="fbSuccess" @fbFail="fbFail" />
            </div>
            <div class="center-xs mt20">
              <div id="balancer">
                <google-login :render-params="renderParams" :params="params" :on-success="gglSuccess" :on-failure="gglFailure" />
              </div>
            </div>
            <button-full class="mb10" type="submit" data-testid="loginSubmit">
              {{ $t('Log in to your account') }}
            </button-full>
            <div class="center-xs">
              {{ $t('or') }}
              <a href="#" @click.prevent="switchElem" data-testid="registerLink">
                {{ $t('register an account') }}
              </a>
            </div>
          </div>
          <!-- <div class="col-sm-6">
            <div class="center-xs mt20">
              <div id="balancer">
                <google-login :renderParams="renderParams" :params="params" :onSuccess="gglSuccess" :onFailure="gglFailure"/>
              </div>
            </div>
            <div class="center-xs mt20">
              <facebook-login @fbSuccess="fbSuccess" @fbFail="fbFail"/>
            </div>
          </div> -->
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Login from '@vue-storefront/core/compatibility/components/blocks/Auth/Login'

import ButtonFull from 'theme/components/theme/ButtonFull.vue'
import BaseCheckbox from '../Form/BaseCheckbox.vue'
import BaseInput from '../Form/BaseInput.vue'
import { required, email } from 'vuelidate/lib/validators'
import GoogleLogin from 'vue-google-login';
import FacebookLogin from './FacebookLogin';
import config from 'config'

export default {
  mixins: [Login],
  components: {
    ButtonFull,
    BaseCheckbox,
    BaseInput,
    GoogleLogin,
    FacebookLogin
  },
  data () {
    return {
      hasRedirect: !!localStorage.getItem('redirect'),
      facebookAuthId: config.socialLogins.facebookOauthId || null,
      params: {
        client_id: config.socialLogins.googleOauthId || 'not loaded'
      },
      renderParams: {
        width: 180,
        height: 28,
        longtitle: true
      },
      isGoogleSignedIn: false,
      gglUser: {},
      isFbSignedIn: false,
      fbUser: {}
    }
  },
  methods: {
    close (e) {
      if (e) localStorage.removeItem('redirect')
      this.$bus.$emit('modal-hide', 'modal-signup')
    },
    login () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        this.$store.dispatch('notification/spawnNotification', {
          type: 'error',
          message: this.$t('Please fix the validation errors'),
          action1: { label: this.$t('OK') }
        })
        return
      }
      this.resetSocialLogins()
      this.callLogin()
    },
    remindPassword () {
      if (!(typeof navigator !== 'undefined' && navigator.onLine)) {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'error',
          message: this.$t('Reset password feature does not work while offline!'),
          action1: { label: this.$t('OK') }
        })
      } else {
        this.callForgotPassword()
      }
    },
    onSuccess () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'success',
        message: this.$t('You are logged in!'),
        action1: { label: this.$t('OK') }
      })
    },
    onFailure (result) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message: this.$t(result.result),
        action1: { label: this.$t('OK') }
      })
    },
    gglSuccess (googleUser) {
      const authResponse = googleUser.getAuthResponse()
      this.resetSocialLogins('facebook')
      this.isGoogleSignedIn = true;
      this.gglUser = authResponse.id_token;
      this.callLogin()
    },
    gglFailure (err) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message: this.$t('Login with Google has failed'),
        action1: { label: this.$t('OK') }
      })
    },
    gglLogout () {
      // TODO determine if needed to be used at all
      // everything is happening on BE
      this.isGoogleSignedIn = false
    },
    fbSuccess (user) {
      this.resetSocialLogins('google')
      this.isFbSignedIn = true
      this.fbUser = user
      this.callLogin()
    },
    fbFail (err) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message: this.$t('Login with Facebook has failed'),
        action1: { label: this.$t('OK') }
      })
    },
    resetSocialLogins (type) {
      switch (type) {
        case 'google': {
          this.email = ''
          this.password = ''
          this.isGoogleSignedIn = false
          this.gglUser = {}
          break;
        }
        case 'facebook': {
          this.email = ''
          this.password = ''
          this.fbUser = {}
          this.isFbSignedIn = false
          break;
        }
        default: {
          this.fbUser = {}
          this.isFbSignedIn = false
          this.isGoogleSignedIn = false
          this.gglUser = {}
          break;
        }
      }
    }
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-error: color(error);
$white: color(white);
  .modal-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .modal-close{
    cursor: pointer;
  }
  .modal-content {
    @media (max-width: 400px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .redirect-error {
    background-color: $color-error;
    color: $white;
  }
  #google-signin {
    color:white;
    min-width: 150px;
    background-color: #000000a1;
    height: 2.5rem;
    border-radius: 2rem;
    font-weight: 400;
    font-size: 0.8rem;
    border:0px;
  }
  #balancer {
    width: 180px;
    margin:auto;
  }
  .row {
    padding-left: 8px;
    padding-right: 8px;
  }
</style>
