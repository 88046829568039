<template>
  <div />
</template>
<script>

export default {
  name: 'Cookies'
};
</script>

<style lang="scss">

.link:hover {
  color: var(--color-anyrent);
  border-color: var(--color-anyrent);
  padding-bottom: 1px;
}

.link {
  text-decoration: none;
  border-bottom: 1px solid var(--color-matterhorn);
}

.link:after {
  border-color: var(--color-anyrent);
  border-bottom: 1px solid red;
}

</style>
