<template>
  <div class="row flex justify-content-center">
    <div v-if="!!label" class="label mr40 col-sm-2 col-xs-12 flex-xs justify-content-center pb25-xs ">
      <label> {{ label }} </label>
    </div>
    <div class="col-sm-10 col-xs-12 ">
      <div class="flex justify-content-center">
        <div class="label label--left inline-block">
          <span> {{ min }} </span>
        </div>
        <div class="slider-wrapper inline-block">
          <div class="slider-value-label "
               :style="`left:calc(${getValueLabelLeftVal}% + (${10 - getValueLabelLeftVal* 0.15}px))`"
          >
            <span class="no-wrap">{{ value }} {{ unit }}</span>
          </div>
          <input type="range" :min="min" :max="max" v-model="value" class="slider">
        </div>
        <div class="label label--right inline-block">
          <span> {{ max }} </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Slider',
  props: {
    min: {
      type: Number,
      default: 0
    },
    unit: {
      type: String,
      default: ''
    },
    max: {
      type: Number,
      default: 100
    },
    label: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      value: this.min + (this.max - this.min) / 2
    };
  },
  computed: {
    getValueLabelLeftVal () {
      return ((this.value - this.min) * 100) / (this.max - this.min);
    }
  },
  watch: {
    value () {
      this.$emit('change', this.value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~theme/css/animations/transitions";
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';

$color-matterhorn: color(matterhorn);
$color-gray: color(gray);
$color-white: color(white);
$color-light-gray: color(light-gray);
$color-partner: color(partner);
.slider-wrapper {
  position: relative;
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 3px;
  background: $color-gray;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 15px;
  height: 15px;
  background-color: $color-white;
  border: 2px solid $color-gray;
  border-radius: 50%;
  cursor: pointer;
}

.slider-value-label {
  position: absolute;
  color: $color-partner;
  left: 50%;
  top: -15px;
  transform: translateX(-50%);
}

.slider-component {
  font-size: 18px;
  display: flex;
  align-items: center;
}
.justify-content-center {
  justify-content: center;
}
.flex {
  display: flex;
}

.label {
  color: $color-gray;
  font-weight: 400;
  padding-top: 5px;
}

.label--left {
  padding-right: 10px;
}

.label--right {
  padding-left: 10px;
}

.no-wrap {
  white-space: nowrap;
}

@media (max-width: 575px) {
  .flex-xs {
    display: flex;
  }
  .pb25-xs {
    padding-bottom: 25px;
  }
}

</style>
