<template>
  <div class="header">
    <header
      class="fixed w-100 bg-cl-primary brdr-cl-secondary"
      :class="{ 'is-visible': navVisible }"
    >
      <div class="container px20">
        <div class="row between-xs middle-xs" v-if="!isCheckoutPage || isThankYouPage">
          <!-- logo -->
          <div class="col-md-4 col-xs-3 middle-xs">
            <!-- <div>
              <hamburger-icon class="p15 icon bg-cl-secondary pointer" />
            </div> -->
            <div>
              <Logo :logos="partnerLogos" :showDividers="displayPartnerProps" />
            </div>
          </div>
          <div class="col-xs-2 visible-xs" />
          <div class="col-md-8 col-xs-4 end-xs ">
            <div class="inline-flex right-icons py5 pt5 align-items-center">
              <div class="nav-divider px15 brdr-cl-accent brdr-right-1">
                <configurator-icon @click.native="toggleConfigurator" />
              </div>
              <compare-icon class="px20 hidden-xs pointer " />
              <div class="flex align-items-startm mt2">
                <microcart-icon class="pointer px20 pt0" />
                <account-icon class="pointer ml40" />
              </div>
              <!-- <search-icon class="p15 icon hidden-xs pointer" /> -->
            </div>
          </div>
        </div>
        <div class="row between-xs middle-xs px15 py5" v-if="isCheckoutPage && !isThankYouPage">
          <div class="col-xs-3 col-md-3 middle-xs">
            <div>
              <router-link
                :to="localizedRoute({ name: 'home' })"
                class="back-button-wrapper links"
              >
                <div class="back-button">
                  <arrow-icon stroke="#4f4f4f" />
                  <span> {{ $t('Back to rental') }} </span>
                </div>
              </router-link>
            </div>
          </div>
          <div class="col-xs-6 col-md-6 center-xs" v-if="!isCheckoutPage">
            <!-- <logo width="auto" height="41px" /> -->
            <div>
              <logo width="auto" height="66px" />
            </div>
          </div>
          <div class="col-xs-3 col-md-3 end-xs">
            <div>
              <a
                v-if="!currentUser"
                href="#"
                @click.prevent="gotoAccount"
                class="cl-tertiary links"
              >{{ $t('Login to your account') }}</a>
              <span v-else>{{ $t('You are logged in as {firstname}', currentUser) }}</span>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="header-placeholder" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import CurrentPage from 'theme/mixins/currentPage';
import AccountIcon from 'theme/components/core/blocks/Header/AccountIcon';
import CompareIcon from 'theme/components/core/blocks/Header/CompareIcon';
import Logo from 'theme/components/core/Logo';
import MicrocartIcon from 'theme/components/core/blocks/Header/MicrocartIcon';
import ConfiguratorIcon from '../../../theme/anyrent/SearchBar/ConfiguratorIcon';
import ArrowIcon from '../ArrowIcon';
import ScrollDirection from '@vue-storefront/core/mixins/ScrollDirection';
import config from 'config';
import sbcrConfig from '@vue-storefront/core/partner-config/config.sbcr.ts';
import highlandSportConfig from '@vue-storefront/core/partner-config/config.highlandsport';

export default {
  name: 'Header',
  components: {
    ArrowIcon,
    ConfiguratorIcon,
    AccountIcon,
    CompareIcon,
    Logo,
    MicrocartIcon
  },
  mixins: [CurrentPage, ScrollDirection],
  data () {
    return {
      navVisible: true,
      searchBarVisibilityTrashold: 54
    };
  },
  mounted () {
    this.searchBarVisibilityTrashold = this.category?.slug === this.$route.params?.slug ? 400 : 54;
    this.$on('scroll-direction-change', this.handleScrollDirectionChange);
  },
  computed: {
    ...mapGetters({
      category: 'category-next/getCurrentCategory'
    }),
    ...mapState({
      currentUser: state => state.user.current
    }),
    isThankYouPage () {
      return this.$store.state.checkout.isThankYouPage
        ? this.$store.state.checkout.isThankYouPage
        : false;
    },
    partnerLogos() {
      switch (this.currentPartner) {
        case sbcrConfig.id:
          return [
            {
              path: '../../assets/any-circle.svg',
              pathResp: '../../assets/any-circle.svg',
              width: 'auto',
              height: '44px'
            },
            {
              path: '../../assets/SBCR_circle.svg',
              pathResp: '../../assets/SBCR_logo_header_plain.svg',
              width: 'auto',
              height: '50px'
            }
          ];
        case highlandSportConfig.id:
          return [
            {
              path: '../../assets/any-circle.svg',
              pathResp: '../../assets/any-circle.svg',
              width: 'auto',
              height: '44px'
            },
            {
              path: '../../assets/highlandsport-logo.svg',
              pathResp: '../../assets/highlandsport-logo.svg',
              width: 'auto',
              height: '50px'
            }
          ];
        default:
          return [{
            path: '../../assets/anylogo.svg',
            pathResp: '../../assets/anylogo.svg',
            width: 'auto',
            height: '66px'
          }];
      }
    },
    currentPartner() {
      return this.$ssrContext?.partnerData.id || config.partnerData.id;
    },
    displayPartnerProps() {
      return this.$ssrContext?.partnerData.displayPartnerPages || config.partnerData.displayPartnerPages;
    },
  },
  methods: {
    handleScrollDirectionChange (event) {
      const dir = event.direction;
      const scrollY = event.scrollY;
      if (scrollY > this.searchBarVisibilityTrashold) {
        if (dir === 'down') {
          this.navVisible = false;
        }
        if (dir === 'up') {
          this.navVisible = true;
        }
      } else {
        this.navVisible = true;
      }
    },
    toggleConfigurator () {
      this.$store.dispatch('ui/toggleConfigurator');
    },
    gotoAccount () {
      this.$bus.$emit('modal-toggle', 'modal-signup');
    }
  },
  watch: {
    '$route' (to, from) {
      this.searchBarVisibilityTrashold = this.category?.slug === to.params?.slug ? 400 : 54;
      this.navVisible = true
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';

$color-icon-hover: color(secondary, $colors-background);
$color-partner: color(partner);

header {
  height: 84px;
  top: -85px;
  z-index: 3;
  transition: top 0.2s ease-in-out;

  &.is-visible {
    top: 0;
  }
}

.line-divider {
  border-right: 1px solid color(accent, $colors-theme);
}

.hover-underline:hover {
  text-decoration: underline;
}

.icon {
  opacity: 0.6;

  &:hover,
  &:focus {

    opacity: 1;
  }
}

.pt1 {
  padding-top: 1px;
}

.ml40 {
  margin-left: 40px;
}

.mt2 {
  margin-top: 2px;
}

.w50 {
  width: 50px;
}

.w24 {
  width: 24px !important;
}

.right-icons {
  //for edge
  float: right;
  padding-right: 5px;
}

.header-placeholder {
  height: 54px;
}

.links {
  text-decoration: underline;
}

.social-link:hover:after {
  display: none;
}

.back-button {
  display: flex;
  align-items: center;
  gap: 15px;
}

.back-button-wrapper:after {
  display: none;
}

.align-items-start {
  align-items: start;
}

.brdr-cl-accent {
  border-color: $color-partner;
}

@media (max-width: 991px) {
  .right-icons {
    padding-right: 7px;
  }
}

@media (max-width: 767px) {
  .right-icons {
    padding-right: 14px;
  }
  .mt10-md {
    margin-top: 10px;
  }
  .row.middle-xs {
    margin: 0 -15px;

    &.py5 {
      margin: 0;
    }
  }
  .col-xs-2:first-of-type {
    padding-left: 0;
  }
  .col-xs-2:last-of-type {
    padding-right: 0;
  }
  a,
  span {
    font-size: 12px;
  }
}

.align-items-center {
  align-items: center;
}

@media (max-width: 575px) {
  .right-icons {
    padding-right: 12px;
  }
}
</style>
