<template>
  <div class="configurator">
    <div @click="toggleConfigurator" class="overlay" />
    <transition name="fade"
                appear
                appear-active-class="fade-enter-active"
                leave-active-class="fade-leave-active"
    >
      <div class="configurator-content">
        <div class="flex flex-direction-column h-100">
          <div class="flex justify-content-flex-end">
            <button
              type="button"
              class="p0 brdr-none bg-cl-transparent close mr40"
              @click="toggleConfigurator"
              data-testid="closeMicrocart"
            >
              <i class="material-icons py20 cl-accent">
                close
              </i>
            </button>
          </div>
          <div class="flex justify-content-center pb20">
            <h1 class="h2 cl-accent">
              {{ $t('Configurator') }}
            </h1>
          </div>
          <div class="select-container mb50">
            <transition name="fade"
                        appear
                        appear-active-class="fade-enter-active"
                        leave-active-class="fade-leave-active"
                        v-for="(child,index) in steps"
                        v-if="hasOption(child && child.options, SELECT)"
                        :key="`select-group_${index}`"
            >
              <select-group
                class="mb20 opacity"
                :row-index="index"
                :button-click-handler="handleOptionClicked"
                :options-layer="child"
                ref="childSelectGroup"
              />
            </transition>
          </div>
          <div class="slider-section mt40">
            <transition
              appear
              name="fade"
              appear-active-class="fade-enter-active"
              leave-active-class="fade-leave-active"
              v-if="child && hasOption(child.options, RANGE)"
              v-for="(child,index) in steps"
              :key="`slider-group_${index}`"
            >
              <slider-group
                class="mb20"
                :row-index="index"
                :slider-change-handler="handleSliderChanged"
                :options-layer="child"
              />
            </transition>
          </div>
          <div class="heading mt50 mt-auto bg-cl-white-smoke h200 pb100-xs">
            <div class="search-button-wrapper mt20 button-spacing" :class="{'search-button-wrapper--acitve':isComplete}">
              <select-button
                class="search-button h42 bg-silver"
                @click.native="resetOptions()"
              >
                Reset
              </select-button>
              <select-button
                class="search-button h42"
                :disabled="!isComplete"
                :selected="true"
                @click.native="handleSearchButtonClicked()"
              >
                {{ $t('Search') }}
              </select-button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import SelectGroup from './SelectGroup';
import SliderGroup from './SliderGroup';
import SelectButton from './SelectButton';
import { SELECT, RANGE } from '../../../../../../constants/EquipmentGuideTypes';

export default {
  name: 'Configurator',
  components: { SelectButton, SelectGroup, SliderGroup },
  data () {
    return {
      SELECT,
      RANGE,
      steps: [],
      isComplete: false,
      baseUrlPath: '',
      query: {}
    };
  },
  computed: {
    ...mapGetters({
      getEquipmentGuide: 'equipmentGuide/getEquipmentGuide'
    })
  },
  mounted () {
    this.steps = [this.getEquipmentGuide];
    this.checkRangeQueries();
  },
  watch: {
    getEquipmentGuide () {
      this.steps = [[this.getEquipmentGuide]];
    },
    steps () {
      this.checkRangeQueries();
    }
  },
  methods: {
    /**
     * Checks whether last step options contains url and related queries. If both conditions applies the baseUrl and query are set.
     */
    checkRangeQueries () {
      const optionGroup = this.steps[this.steps.length - 1];
      const query = this.getQueryFromOptions(optionGroup.options);
      if (query) {
        this.query = query;
      }
    },
    /**
     * Returns search query object from options with option type RANGE
     * @param options
     * @returns {Array<any>}
     */
    getQueryFromOptions (options) {
      return options
        .filter(option => option.option_type === RANGE)
        .reduce((acc, option) => {
          const min = option.option_value.minimum;
          const max = option.option_value.maximum;
          const value = min + (max - min) / 2;

          const split = this.getUrlQueryFromRangeOption(option, value);
          if (split) {
            const c = split || {};
            return { ...acc, ...c };
          }
        }, {});
    },
    // Handlers
    /**
     * Handles event when select option is clicked within step
     * @param option - option with option type SELECT
     * @param stepIndex  - index of the step in which option is placed
     */
    handleOptionClicked (option, stepIndex) {
      this.baseUrlPath = '';
      this.query = {};

      let steps = [...this.steps];
      this.baseUrlPath = option.base_url_path ? option.base_url_path : '';

      if (stepIndex <= this.steps.length - 1) {
        steps = steps.slice(0, stepIndex + 1);
      }
      if (option.child && option.child.category_name) {
        this.isComplete = option.child?.options?.length && option.child.options[0].option_type === RANGE;
        this.steps = [...steps, option.child];
      } else {
        this.steps = steps;
        if (option.base_url_path) {
          this.isComplete = true;
        }
      }
    },
    resetOptions () {
      this.steps = [this.getEquipmentGuide];
      this.isComplete = false;
      this.baseUrlPath = '';
      this.checkRangeQueries();
      this.$refs.childSelectGroup[0].selectedOption = undefined
    },
    /**
     *
     * @param sliderOption - option with option type RANGE
     * @param value - actual slider value
     */
    handleSliderChanged (sliderOption, value) {
      const query = this.getUrlQueryFromRangeOption(sliderOption, value);
      this.query = { ...this.query, ...query };
    },
    handleSearchButtonClicked () {
      if (this.baseUrlPath) {
        this.$router.push({ path: this.baseUrlPath, query: this.query });
        this.toggleConfigurator();
      }
    },
    /**
     * Returns url query as object, based on RANGE option and selected value
     * @param option - option with option type RANGE
     * @param value - numerical value from RANGE option
     * @returns {{}|null}
     */
    getUrlQueryFromRangeOption (option, value) {
      if (option.option_type !== RANGE) {
        return null;
      }
      const sorted = option.option_value.splits
        .sort((a, b) => a.lte_value - b.lte_value);
      let split = sorted.find(spl => spl.lte_value > value);
      if (!split && sorted.length) {
        split = sorted[sorted.length - 1];
      }
      if (split) {
        return this.stringToQueryObject(split.query_string);
      }
      return null;
    },
    /**
     * Return true when options contain option with specified type
     * @param options
     * @param type - option type
     * @returns {boolean}
     */
    hasOption (options, type) {
      return options && !!options.find(option => option.option_type === type);
    },
    /**
     * Returns query object based on url query string pattern
     * @param str
     * @returns {{}}
     */
    stringToQueryObject (str) {
      const params = new URLSearchParams(str);
      const result = {};
      for (const [key, value] of params.entries()) {
        if (!result[key]) {
          result[key] = [];
        }
        result[key] = [...result[key], value];
      }
      return result;
    },
    /**
     * toggle configurator
     */
    toggleConfigurator () {
      this.$store.dispatch('ui/toggleConfigurator');
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
@import '~theme/css/components/_checkboxes';
@import '~theme/css/layout/_margin';

$color-silver: color(silver);
$color-white-smoke: color(white-smoke);
$color-active: color(partner);
$color-white: color(white);
$color-gray: color(gray);
$color-partner: color(partner);
$color-matterhorn: color(matterhorn);

.configurator {
  top: 0;
  left: 0;
  z-index: 4;
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.bg-cl-white-smoke{
  background-color: $color-white-smoke;
}

.mr40 {
  margin-right: 40px;
}

.configurator-content {
  width: calc(100vw - 70px);
  max-width: 1200px;
  height: 90%;
  background-color: white;
  margin-top: 50px;
  overflow: scroll;
  z-index: 1;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.7;
}

.heading {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.blur {
  filter: blur(3px);
}

.back-button {
  height: 55px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.search-button {
  background-color: $color-partner;
  color: $color-white;
}

.search-button-wrapper {
  background-color: transparent;
}

.search-button-wrapper--active {
  background-color: unset;
}

.fade-enter-to {
  opacity: 1;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.h100{
  height: 100px;
}
.mt-auto {
  margin-top: auto;
}
.h42 {
  height: 42px;
}
.pb20 {
  padding-bottom: 20px;
}

.flex-direction-column {
  flex-direction: column;
}

.bg-silver {
  background-color: color(silver);
}

.button-spacing {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
}

@media (max-width: 575px) {
  .configurator-content {
    width: 100%;
    height: 100%;
    margin: 0;
  }
  .pb100-xs {
    padding-bottom: 100px;
  }
  .overlay {
    display: none;
  }
}
</style>
