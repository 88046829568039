<template>
  <div class="window-container">
    <div @click="$emit('close')" class="overlay" />
    <div class="content">
      <div class="top-panel ">
        <cross-button class="cross-button pr10-xs" @click="$emit('close')" />
      </div>
      <div class="form-container">
        <chat-bot-form @close="$emit('close')" />
      </div>
    </div>
  </div>
</template>
<script>

import CrossButton from '../../CrossButton';
import ChatBotForm from './ChatBotForm';

export default {
  name: 'ChatBotModal',
  components: { ChatBotForm, CrossButton },
  props: {
    header: {
      type: String
    }
  },
  mounted () {
    this.$store.dispatch('ui/setAppScrollLock', true);
  },
  beforeDestroy () {
    this.$store.dispatch('ui/setAppScrollLock', false);
  }
};
</script>

<style lang="scss" scoped>
.overlay {
  cursor: pointer;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.7;
}

.window-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.content {
  background-color: white;
  padding-bottom: 50px;
  width: 800px;
  height: 484px;
  font-family: ttcommons-regular, sans-serif;
  z-index: 2001;
}

.form-container {
  height: 100%;
  padding-top: 15px;
}

.cross-button {
  margin-right: 40px;
  width: 10px;
}

.top-panel {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: end;
}

@media (max-width: 768px) {
  .cross-button {
    margin-right: 20px
  }
  .content {
    width: 100vw;
  }
}

@media (max-width: 572px) {
  .form-container {
    padding-left: 0;
    padding-right: 0;
  }
  .cross-button {
    margin-right: 20px
  }

  .top-panel {
    height: 55px;
  }

  .pr10-xs {
    padding-right: 20px;
  }

  .content {
    width: 100vw;
    height: 100vh;
    padding: 0;
  }

  .overlay {
    display: none;
  }
}

</style>
