var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header',{staticClass:"modal-header py25 px65 h1 serif weight-700 bg-cl-secondary"},[_vm._v("\n    "+_vm._s(_vm.$t('Log in'))+"\n    "),_c('i',{staticClass:"modal-close material-icons cl-bg-tertiary",attrs:{"slot":"close"},on:{"click":_vm.close},slot:"close"},[_vm._v("\n      close\n    ")])]),_vm._v(" "),(_vm.hasRedirect)?_c('div',{staticClass:"pt10 pb10 px65 redirect-error"},[_c('p',{staticClass:"h5 mb0 mt0"},[_vm._v("\n      "+_vm._s(_vm.$t('You need to be logged in to see this page'))+"\n    ")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"modal-content bg-cl-primary pt30 pb60 px65 cl-secondary"},[_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.login($event)}}},[_c('base-input',{staticClass:"mb10",attrs:{"type":"email","name":"email","focus":"","placeholder":_vm.$t('E-mail address *'),"validations":[
          {
            condition: !_vm.$v.email.required && _vm.$v.email.$error,
            text: _vm.$t('Field is required.')
          },
          {
            condition: !_vm.$v.email.email && _vm.$v.email.$error,
            text: _vm.$t('Please provide valid e-mail address.')
          }
        ]},on:{"blur":function($event){return _vm.$v.email.$touch()}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_vm._v(" "),_c('base-input',{staticClass:"mb10",attrs:{"type":"password","name":"password","placeholder":_vm.$t('Password *'),"validations":[{
          condition: !_vm.$v.password.required && _vm.$v.password.$error,
          text: _vm.$t('Field is required.')
        }]},on:{"blur":function($event){return _vm.$v.password.$touch()}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_vm._v(" "),_c('div',{staticClass:"row"},[_c('base-checkbox',{staticClass:"col-xs-7 col-sm-6 mb20",attrs:{"id":"remember"},model:{value:(_vm.remember),callback:function ($$v) {_vm.remember=$$v},expression:"remember"}},[_vm._v("\n          "+_vm._s(_vm.$t('Remember me'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"col-xs-5 col-sm-6 mb35 flex end-xs middle-xs"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.remindPassword($event)}}},[_vm._v("\n            "+_vm._s(_vm.$t('Forgot the password?'))+"\n          ")])])],1),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"center-xs mt20"},[_c('facebook-login',{on:{"fbSuccess":_vm.fbSuccess,"fbFail":_vm.fbFail}})],1),_vm._v(" "),_c('div',{staticClass:"center-xs mt20"},[_c('div',{attrs:{"id":"balancer"}},[_c('google-login',{attrs:{"render-params":_vm.renderParams,"params":_vm.params,"on-success":_vm.gglSuccess,"on-failure":_vm.gglFailure}})],1)]),_vm._v(" "),_c('button-full',{staticClass:"mb10",attrs:{"type":"submit","data-testid":"loginSubmit"}},[_vm._v("\n            "+_vm._s(_vm.$t('Log in to your account'))+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"center-xs"},[_vm._v("\n            "+_vm._s(_vm.$t('or'))+"\n            "),_c('a',{attrs:{"href":"#","data-testid":"registerLink"},on:{"click":function($event){$event.preventDefault();return _vm.switchElem($event)}}},[_vm._v("\n              "+_vm._s(_vm.$t('register an account'))+"\n            ")])])],1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }