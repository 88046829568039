import { render, staticRenderFns } from "./PartnerModalSbcr.vue?vue&type=template&id=6bc0a0a1&scoped=true&"
import script from "./PartnerModalSbcr.vue?vue&type=script&lang=js&"
export * from "./PartnerModalSbcr.vue?vue&type=script&lang=js&"
import style0 from "./PartnerModalSbcr.vue?vue&type=style&index=0&id=6bc0a0a1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bc0a0a1",
  null
  
)

export default component.exports