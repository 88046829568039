<template>
  <div class="select-group">
    <div class="heading">
      <h2 class="h2 cl-accent">
        {{ optionsLayer && optionsLayer.category_name ? optionsLayer.category_name : '...' }}
      </h2>
    </div>
    <div class="options">
      <slot />
      <select-button
        :key="`select_${index}`"
        v-for="(option, index) in optionsLayer.options"
        v-if="option.option_type === SELECT"
        @click.native="()=>handleOptionClicked(option, index)"
        :selected="isSelectedOption(index)"
      >
        <span class="span">{{ option.label }}</span>
      </select-button>
    </div>
  </div>
</template>
<script>
import SelectButton from './SelectButton';
import { SELECT } from '../../../../../../constants/EquipmentGuideTypes';

export default {
  name: 'SelectGroup',
  components: { SelectButton },
  props: {
    optionsLayer: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rowIndex: {
      type: Number,
      default: 0
    },
    buttonClickHandler: {
      type: Function,
      default: () => {
      }
    }
  },
  data () {
    return {
      selectedOption: undefined,
      SELECT
    };
  },
  methods: {
    isSelectedOption (index) {
      return this.selectedOption === index;
    },
    handleOptionClicked (option, index) {
      if (this.disabled) {
        return;
      }
      this.buttonClickHandler(option, this.rowIndex);
      this.selectedOption = index;
    }
  },
  watch: {
    optionsLayer () {
      this.selectedOption = null;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~theme/css/components/_checkboxes';
@import '~theme/css/layout/_margin';
$color-matterhorn: color(matterhorn);

.heading {
  display: flex;
  justify-content: center;
}

.h2 {
  font-size: 18px;
  font-weight: lighter;
  font-family: TTCommons-Regular, sans-serif;
}

.options {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
}

.span {
  font-family: TTCommons-Regular, sans-serif;
}
</style>
