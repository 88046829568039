<template>
  <button
    type="button"
    class="relative bg-cl-transparent brdr-none inline-flex icon-size"
    @click="openMicrocart"
    data-testid="openMicrocart"
    :aria-label="$t('Open microcart')"
  >
    <img src="/assets/cart.svg"
         class="icon-size"
         :alt="$t('defaultTitle')"
    >
    <span
      class="minicart-count absolute flex center-xs middle-xs border-box py0 px2 h6 lh16 weight-700 cl-white bg-cl-silver"
      v-cloak
      v-show="totalQuantity"
      data-testid="minicartCount"
    >
      {{ totalQuantity }}
    </span>
  </button>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MicrocartIcon from '@vue-storefront/core/compatibility/components/blocks/Header/MicrocartIcon'

export default {
  // mixins: [MicrocartIcon],
  mounted () {
    this.handleVisibilityChange = () => {
      if (!document.hidden) {
        this.$store.dispatch('cart/load')
      }
    }
    document.addEventListener('visibilitychange', this.handleVisibilityChange)
  },
  beforeDestroy () {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange)
  },
  computed: {
    ...mapGetters({
      totalQuantity: 'cart/getItemsTotalQuantity'
    })
  },
  methods: {
    ...mapActions({
      openMicrocart: 'ui/toggleMicrocart'
    })
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';

$color-anyrent: color(anyrent);

.icon-size {
  width: 25px;
  height: 25px;
}
.minicart-count {
  padding-top: 1px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 12px;
  top: 15px;
  left: 32px;
  min-width: 15px;
  min-height: 15px;
  border-radius: 10px;
  border: 1px solid white;
  background-color: $color-anyrent;
}

@media (min-width: 768px) {
  .icon-size {
    width: 32px;
    height: 32px;
  }
  .minicart-count {
    top: 18px;
    left: 36px;
    padding-left: 0px;
    padding-right: 0px;
    min-width: 20px;
    min-height: 20px;
  }
}

</style>
