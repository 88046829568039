<template>
  <button
    :disabled="disabled"
    class="button"
    :class="{
      'button--selected' : selected,
      'button--non-selected':!selected,
      'button--disabled':disabled }"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'SelectButton',
  props: {
    selected: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~theme/css/animations/transitions";
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';

$color-partner: color(partner);
$color-gray: color(gray);
$color-silver: color(silver);
$color-white: color(white);

.button--selected {
  background-color: $color-partner;
}

.button--non-selected {
  background-color: $color-silver;
}

.button--disabled {
  filter: alpha(opacity=50);
}

.button {
  border-radius: 40px;
  color: $color-white;
  height: 55px;
  width: 165px;
  font-size: 18px;
  padding-left: 5px;
  padding-right: 5px;
}

</style>
